import { locationSwitch } from '../switch/locationSwitch';

export const mapOwnerData = (owner) => {
  const isCompany = owner?.firstName === null;
  const organizationNumber = isCompany ? owner?.idNumber : null;
  const idNumber = isCompany ? '' : owner?.idNumber || '';

  return {
    firstName: owner?.firstName || '',
    lastName: owner?.lastName || '',
    idNumber: idNumber,
    organizationNumber: organizationNumber,
    isCompany: isCompany,
    address: {
      address: owner?.address || '',
      city: owner?.city || '',
      zip: owner?.zip || '',
    },
  };
};

export const mapVehicleData = (vehicleData, location) => {
  const registrationLocation = locationSwitch(location);
  return {
    registrationNumberCountry: registrationLocation || vehicleData?.firstRegistrationCountry || '',
    registrationNumber: vehicleData?.registrationNumber || '',
    insuranceCompany: vehicleData?.insurance?.name || '',
    dateOfFirstRegistration: vehicleData?.dateOfFirstRegistration || '',
    firstRegistrationCountry: vehicleData?.firstRegistrationCountry || '',
    year: vehicleData?.year || '',
    vin: vehicleData?.vin || '',
    make: vehicleData?.make || '',
    model: vehicleData?.model || '',
    bodyType: vehicleData?.bodyType || '',
    colour: vehicleData?.colour || '',
    technicalData: {
      maxTrailerWeight: vehicleData?.technicalData.maxTrailerWeight || '',
      automaticTransmission: vehicleData?.technicalData.fourWheelDrive || '',
      fourWheelDrive: vehicleData?.technicalData.fourWheelDrive || '',
      wheelDimensions: vehicleData?.technicalData.wheelDimensions || '',
      wheelbase: vehicleData?.technicalData.wheelbase || '',
      fuelType: vehicleData?.technicalData?.fuelTypes?.[0] || '',
      numberOfAxlesInOperation: vehicleData?.technicalData.numberOfAxlesInOperation || '',
      totalNumberOfAxles: vehicleData?.technicalData?.totalNumberOfAxles || '',
      weight: vehicleData?.weight || '',
      totalWeight: vehicleData?.technicalData.totalWeight || '',
      width: vehicleData?.width || '',
      length: vehicleData?.length || '',
    },
  };
};
