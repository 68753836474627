import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@mui/material';
import { DateField, TimeField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export const CaseDate = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const currentDate = dayjs();

  useEffect(() => {
    if (!values[name]) {
      const defaultValue = currentDate.toISOString();
      setFieldValue(name, defaultValue);
    }
  }, [currentDate, name, setFieldValue, values]);

  const handleDateChange = (newDate) => {
    if (newDate) {
      const time = dayjs(values[name]).format('HH:mm');
      const updatedValue = newDate.set('hour', parseInt(time.split(':')[0])).set('minute', parseInt(time.split(':')[1]));
      setFieldValue(name, updatedValue.toISOString());
    }
  };

  const handleTimeChange = (newTime) => {
    if (newTime) {
      const date = dayjs(values[name]).format('YYYY-MM-DD');
      const updatedValue = dayjs(`${date}T${newTime.format('HH:mm')}`);
      setFieldValue(name, updatedValue.toISOString());
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <DateField label="Date" value={values[name] ? dayjs(values[name]) : currentDate} onChange={handleDateChange} format="DD/MM/YYYY" />
          <TimeField label="Time" value={values[name] ? dayjs(values[name]) : currentDate} onChange={handleTimeChange} format="HH:mm" />
        </Box>
        <Typography variant="caption">* Du må velge dato og tid for assistansen</Typography>
      </LocalizationProvider>
    </Box>
  );
};
