import { Box } from '@mui/material';
import React from 'react';
import UrgentCaseAlert from '../content/UrgentCaseAlert';
import SalesForceButton from '../../Button/SalesForceButton';
import { useRsaDataStore } from '../../../hooks/store';

const HeaderDisplay = () => {
  const rsaCaseDetails = useRsaDataStore((state) => state.rsaCaseDetails);

  let isUrgent;
  let caseId;

  if (rsaCaseDetails) {
    isUrgent = rsaCaseDetails.priority === '1. IMMEDIATE PRIORITY' || rsaCaseDetails.priority === '2. HIGH PRIORITY';

    caseId = rsaCaseDetails.caseId;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        alignContent: 'end',
      }}
    >
      {isUrgent && <UrgentCaseAlert />}
      <SalesForceButton id={caseId} />
    </Box>
  );
};

export default HeaderDisplay;
