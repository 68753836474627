import React, { useEffect } from 'react';
import SearchResultsDisplay from '../../../UI/UserFeedback/SearchResults/SearchResultsDisplay';
import { Card, Typography } from '@mui/material';
import { usePcdStore } from '../../../../hooks/store/usePcdStore';
import { RegNumSearch } from './RegNumSearch';

export const RegistrationContainer = () => {
  const { pcdData, isLoading, isError } = usePcdStore();

  useEffect(() => {
    console.log('Data fra TFF/PCD: ', pcdData);
  }, [pcdData]);

  return (
    <>
      <RegNumSearch />
      {pcdData && Object.keys(pcdData).length > 0 && (
        <Card sx={{ boxShadow: 4, padding: 2 }}>
          <Typography variant="h3" marginBottom={2}>
            Resultater:
          </Typography>
          <SearchResultsDisplay hasSearched={true} loading={isLoading} pcdErrorMessage={isError} rsaErrorMessage={isError} />
        </Card>
      )}
    </>
  );
};
