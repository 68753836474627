import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import OwnerInfoDialog from '../Dialog/OwnerInfoDialog';
import OpenDialog from '../Button/OpenDialog';

const CarContainer = ({ data }) => {
  const [open, setOpen] = useState(false);

  const ownerData = data.owner?.[0] || {};
  const isCompany = ownerData.idNumber && ownerData.idNumber.toString().length >= 9;



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <HelpCenterIcon color="info" />
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <Box>
            <Typography>Hvem er oppført som eier av bilen?</Typography>
            <Typography>
              Svar: <strong>{isCompany ? ownerData.companyName : ownerData.firstName + ' ' + ownerData.lastName}</strong>
            </Typography>
          </Box>
          <Box>
            <Typography>Hvilken modell og merke er bilen?</Typography>
            <Typography>
              Svar:{' '}
              <strong>
                {data?.vehicleData?.make + ' ' + data?.vehicleData?.model} - {data?.vehicleData?.year}
              </strong>
            </Typography>
          </Box>
        </Box>
      </Box>
      <OpenDialog onClick={handleClickOpen} />
      <OwnerInfoDialog data={data} open={open} handleClose={handleClose} />

    </Box>
  );
};

export default CarContainer;
