export const createCaseInitialValuies = {
  pcdData: {
    owner: {
      firstName: '',
      lastName: '',
      idNumber: null,
      organizationNumber: null,
      isCompany: false,
      address: {
        address: '',
        city: '',
        zip: null,
      },
    },
    vehicle: {
      registrationNumberCountry: 'Norway',
      insuranceCompany: '',
      dateOfFirstRegistration: '',
      year: '',
      firstRegistrationCountry: '',
      vin: '',
      make: '',
      model: '',
      bodyType: '',
      colour: '',
      technicalData: {
        fuelType: '',
        numberOfAxlesInOperation: '',
        totalNumberOfAxles: '',
        length: '',
        width: '',
        totalWeight: '',
        weight: '',
        maxTrailerWeight: '',
        automaticTransmission: false,
        fourWheelDrive: false,
        wheelDimensions: [],
        wheelbase: '',
      },
    },
  },
  onSiteContact: {
    contactName: '',
    countryCode: '+47',
    phone: null,
  },
  channel: '',
  location: {
    locationSearch: '',
    locationMessage: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    countryCodeISO: '',
    lon: '',
    lat: '',
  },
  incident: {
    incidentCause: '',
    incidentDescription: '',
    priority: '3',
  },
  dateTime: null,
  searchValues: {
    registrationNumberCountry: 'Norway',
    countryCode: 'NO',
    registrationNumber: '',
    searchTerm: '',
  },
};
