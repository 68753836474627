import React from 'react';
import { InputAdornment, TextField, FormControl, CircularProgress, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { useField, useFormikContext } from 'formik';
import { useRegistrationInputChange } from '../../../../hooks/handler/useRegistrationInputChange';
import { RegistrationCountry } from './RegistrationCountry';
import { usePcdSearch } from '../../../../hooks/Search/usePcdSearch';

export const RegNumSearch = () => {
  const [field, meta] = useField('searchValues.registrationNumber');
  const { setFieldValue, values } = useFormikContext();
  const { isError, isLoading } = usePcdSearch();
  const { handleInputChange } = useRegistrationInputChange();
  const registrationNumber = values.searchValues.registrationNumber;
  const countryCode = values.searchValues.countryCode;

  const handleUpperCaseChange = (e) => {
    const cursorPosition = e.target.selectionStart;
    const originalValue = e.target.value;
    const upperCaseValue = originalValue.toUpperCase();
    if (originalValue !== upperCaseValue) {
      setFieldValue('searchValues.registrationNumber', upperCaseValue);
    }

    handleInputChange({ ...e, target: { ...e.target, value: upperCaseValue } }, countryCode);

    setTimeout(() => {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
    }, 0);
  };

  return (
    <Tooltip title="Søk på registreringsnummer" placement="top" arrow>
      <FormControl fullWidth error={!!(isError || meta.error)}>
        <TextField
          {...field}
          variant="outlined"
          name="searchValues.registrationNumber"
          label="Skriv inn registreringsnummer"
          placeholder="Skriv inn registreringsnummer"
          value={registrationNumber}
          onChange={handleUpperCaseChange}
          error={!!(isError || meta.error)}
          helperText={isError ? 'Error occurred while searching' : meta.error || ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RegistrationCountry />
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">{isLoading === 'loading' ? <CircularProgress size={24} /> : isError === 'error' ? <ErrorIcon color="error" /> : null}</InputAdornment>,
          }}
        />
      </FormControl>
    </Tooltip>
  );
};
