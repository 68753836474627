import { useMutation } from '@tanstack/react-query';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';

export const usePostRsaCase = () => {
  const axiosInstance = useAxiosInstance();

  return useMutation({
    mutationFn: async (body) => {
      const urlRsa = process.env.REACT_APP_RSA_API_BASE_URL;
      try {
        const response = await axiosInstance.post(urlRsa, body);
        const responseData = {
          data: response.data,
          status: response.status,
          statusText: response.statusText,
        };

        localStorage.setItem('response', JSON.stringify(response.data));
        return responseData;
      } catch (error) {
        console.error('API call failed:', error.message);
        throw error;
      }
    },
  });
};
