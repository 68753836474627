import React, { useState, useEffect } from 'react';
import { Box, Card, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SectionHeading from './SectionHeading';

const Section = ({ heading, children, hasError, isComplete, incured }) => {
  const [isOpen, setIsOpen] = useState(!isComplete || hasError);

  useEffect(() => {
    setIsOpen(!isComplete || hasError);
  }, [hasError, isComplete]);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const borderColor = hasError ? 'red' : isComplete ? 'green' : 'red';

  return (
    <Box
      component={Card}
      sx={{
        marginBottom: 3,
        marginLeft: 2,
        marginRight: 2,
        border: `2px solid ${borderColor}`,
        borderRadius: '8px',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" alignContent="center" justifyItems="center" sx={{ cursor: 'pointer' }} onClick={handleToggle}>
        <SectionHeading title={heading} />
        <Box display="flex" alignItems="center" gap={1}>
          {!isComplete ? <ErrorIcon sx={{ color: 'orange' }} /> : isComplete ? <CheckCircleIcon sx={{ color: 'green' }} /> : null}
          <IconButton size="small">
            <ExpandMoreIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: 2,
          }}
        >
          {incured}
          <Box display={'flex'}>{children}</Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Section;
