import { useFormikContext } from 'formik';
import { usePcdStore } from '../store/usePcdStore';
import { validateLicensePlate } from '../../utils/validateRegistrationNumber';
import { usePcdSearch } from '../Search/usePcdSearch';
import { useMemo } from 'react';
import { debounce } from 'lodash';

export const useRegistrationInputChange = () => {
  const { setFieldValue, setFieldError } = useFormikContext();
  const { fetchRegData } = usePcdSearch();
  const { setRegistrationNumber } = usePcdStore();

  const debouncedValidationAndFetch = useMemo(
    () =>
      debounce((newRegistrationNumber, countryCode) => {
        const error = validateLicensePlate(countryCode, newRegistrationNumber);

        if (error) {
          setFieldError('searchValues.registrationNumber', error);
        } else {
          setFieldError('searchValues.registrationNumber', null);
          fetchRegData(newRegistrationNumber, countryCode);
        }
      }, 1500),
    [setFieldError, fetchRegData]
  );

  const handleInputChange = (e, countryCode) => {
    const newRegistrationNumber = e.target.value.trim();
    setRegistrationNumber(newRegistrationNumber);
    setFieldValue('searchValues.registrationNumber', newRegistrationNumber);
    debouncedValidationAndFetch(newRegistrationNumber, countryCode);
  };

  return { handleInputChange };
};
