import React from 'react';
import { Box } from '@mui/material';
import { CaseDetailsContainer } from '../../components/Container/CaseDetailsContainer';
import { CaseInfoContainer } from '../../components/Container/CaseInfoContainer';

export const ReviewLayout = ({ caseData }) => {
  if (!caseData) return;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <CaseInfoContainer name={caseData.contact?.name} phone={caseData.contact?.phoneNumber} location={caseData.location?.address} />
      <CaseDetailsContainer data={caseData} />
    </Box>
  );
};
