import { useState, useEffect, useCallback } from 'react';
import { validatePhoneNumber } from '../../pages/newCase/validation/validatePhoneNumber';
import { phoneNumberFormat } from '../../utils/format/phoneNumerFormat';

export const usePhoneNumberHandlers = (values, setFieldValue) => {
  const [selectedCountry, setSelectedCountry] = useState(values.onSiteContact.countryCode || phoneNumberFormat[0].code);
  const [inputError, setInputError] = useState('');

  const handleValidation = useCallback((number, country) => {
    const validationError = validatePhoneNumber(number, country);
    setInputError(validationError);
  }, []);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value.replace(/\D+/g, '').trim();
      handleValidation(value, selectedCountry);
      setFieldValue('onSiteContact.phone', value);
    },
    [selectedCountry, setFieldValue, handleValidation]
  );

  const handleCountryChange = useCallback(
    (e) => {
      const newCountry = e.target.value;
      setSelectedCountry(newCountry);
      setFieldValue('onSiteContact.countryCode', newCountry);
      handleValidation(values.onSiteContact.phone, newCountry);
    },
    [setFieldValue, handleValidation, values.onSiteContact.phone]
  );

  useEffect(() => {
    handleValidation(values.onSiteContact.phone, selectedCountry);
  }, [values.onSiteContact.phone, selectedCountry, handleValidation]);

  return {
    selectedCountry,
    inputError,
    handleInputChange,
    handleCountryChange,
  };
};
