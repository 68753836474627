import React from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { cardSx } from '../../../Styles/defaultStyle';

const SearchResultCard = ({ addressSearch, onClose }) => {
  const { address, position, poi } = addressSearch;
  const { lat, lon } = position || {};

  return (
    <Box component={Card} sx={cardSx}>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <IconButton color="error" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
      <Box marginLeft={2} marginRight={2} marginTop={1}>
        <Typography variant="h3" component="h3" sx={{ marginBottom: 1 }}>
          {poi?.name ? poi?.name : 'Sted funnet'}
        </Typography>
        <Typography variant="body1" component="p">
          {address.freeformAddress || address}
        </Typography>
        <Typography variant="body1" component="p">
          <i>
            <small>
              Breddegrad: {lat ? lat.toFixed(3) : 'N/A'} - Lengdegrad: {lon ? lon.toFixed(3) : 'N/A'}
            </small>
          </i>
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchResultCard;
