import { Alert, Typography } from '@mui/material';

export const LocationHelperText = ({ address }) => {
  return (
    <Alert variant="filled" severity="success">
      <Typography variant="caption">
        Skade stedet er satt til: <strong>{address}</strong>
      </Typography>
    </Alert>
  );
};
