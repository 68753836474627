export const coverCauses = [
  // Collision
  { id: 101, value: 'Collision', label: 'Kollisjon', category: 'Collision', lvl: 3 },
  { id: 102, value: 'Collision with another vehicle', label: 'Kollisjon med annen bil', category: 'Collision', lvl: 4 },
  { id: 103, value: 'Collision with stationary object', label: 'Kollisjon med gjenstand', category: 'Collision', lvl: 4 },
  { id: 104, value: 'Collision with moose/deer', label: 'Kollisjon med vilt', category: 'Collision', lvl: 4 },
  { id: 105, value: 'Collision with other animal', label: 'Kollisjon med annet dyr', category: 'Collision', lvl: 4 },

  // Other accident
  { id: 106, value: 'Other accident', label: 'Annet uhell', category: 'Other accident', lvl: 3 },
  { id: 107, value: 'Driven off road, drivable', label: 'Utforkjøring, trolig kjørbar', category: 'Other accident', lvl: 4 },
  { id: 108, value: 'Driven off road, non-drivable', label: 'Utforkjøring, ikke kjørbar', category: 'Other accident', lvl: 4 },
  { id: 109, value: 'Stuck on road / driveway', label: 'Fastkjørt på vei', category: 'Other accident', lvl: 4 },

  // Body / accessories
  { id: 201, value: 'Body / accessories', label: 'Chassis/ påbygg', category: 'Body / accessories', lvl: 3 },
  { id: 202, value: 'Door locks not opening', label: 'Utelåst', category: 'Body / accessories', lvl: 4 },
  { id: 203, value: 'Exhaust pipe problem', label: 'Eksosanlegg', category: 'Body / accessories', lvl: 4 },
  { id: 204, value: 'Windscreen / window broken', label: 'Rute / vindusvisker', category: 'Body / accessories', lvl: 4 },

  // Brakes
  { id: 205, value: 'Brakes', label: 'Bremser', category: 'Brakes', lvl: 3 },
  { id: 206, value: 'Brakes not connecting', label: 'Bremser virker ikke', category: 'Brakes', lvl: 4 },
  { id: 207, value: 'Brakes not disconnecting', label: 'Bremser sitter fast', category: 'Brakes', lvl: 4 },
  { id: 208, value: 'Abnormal noise from brakes', label: 'Bremser, ulyd', category: 'Brakes', lvl: 4 },

  // Does not start
  { id: 209, value: 'Does not start', label: 'Startvansker', category: 'Does not start', lvl: 3 },
  { id: 210, value: 'Does not start, has no electricity, away from home', label: 'Startvansker på vei', category: 'Does not start', lvl: 4 },
  { id: 211, value: 'Does not start, has no electricity, at home address', label: 'Startvansker hjemme', category: 'Does not start', lvl: 4 },

  // Electrical
  { id: 212, value: 'Electrical problem', label: 'Elektrisk problem', category: 'Electrical', lvl: 3 },
  { id: 213, value: 'Lost electricity when driving', label: 'Mistet strøm under kjøring', category: 'Electrical', lvl: 4 },
  { id: 214, value: 'Malfunction of electrical device', label: 'Feil på elektrisk utstyr', category: 'Electrical', lvl: 4 },
  { id: 215, value: 'Heating/air con not working', label: 'Varme / AC virker ikke', category: 'Electrical', lvl: 4 },
  { id: 216, value: 'Headlights/other lights broken', label: 'Lys', category: 'Electrical', lvl: 4 },

  // Engine
  { id: 217, value: 'Engine failure', label: 'Motorfeil', category: 'Engine', lvl: 3 },
  { id: 218, value: 'Lost power suddenly', label: 'Motorstopp', category: 'Engine', lvl: 4 },
  { id: 219, value: 'Smoke/Overheating', label: 'Røykutvikling', category: 'Engine', lvl: 4 },
  { id: 220, value: 'Abnormal noise from engine', label: 'Ulyd fra motor', category: 'Engine', lvl: 4 },

  // Leak
  { id: 221, value: 'Leak', label: 'Lekkasje', category: 'Leak', lvl: 3 },
  { id: 222, value: 'Leaking air', label: 'Luftlekkasje', category: 'Leak', lvl: 4 },
  { id: 223, value: 'Leaking coolant', label: 'Kjølevæskelekkasje', category: 'Leak', lvl: 4 },
  { id: 224, value: 'Leaking oil', label: 'Oljelekkasje', category: 'Leak', lvl: 4 },
  { id: 225, value: 'Leaking fuel', label: 'Drivstofflekkasje', category: 'Leak', lvl: 4 },

  // Other breakdown
  { id: 226, value: 'Other breakdown', label: 'Driftsstans, annet', category: 'Other breakdown', lvl: 3 },
  { id: 227, value: 'Trailer broken', label: 'Tilhenger', category: 'Other breakdown', lvl: 4 },
  { id: 228, value: 'Problem connecting to trailer', label: 'Tilhenger, tilkoblingsproblem', category: 'Other breakdown', lvl: 4 },
  { id: 246, value: 'Hydraulics problem', label: 'Hydraulikk', category: 'Other breakdown', lvl: 4 },

  // Steering
  { id: 229, value: 'Steering', label: 'Styring', category: 'Steering', lvl: 3 },
  { id: 230, value: 'Steering went heavy suddenly', label: 'Styring ble plutslig tung', category: 'Steering', lvl: 4 },
  { id: 231, value: 'Ignition/steering lock stuck', label: 'Tenning / rattlås', category: 'Steering', lvl: 4 },

  // Suspension / chassis
  { id: 232, value: 'Suspension / chassis', label: 'Hjuloppheng', category: 'Suspension / chassis', lvl: 3 },
  { id: 233, value: 'Air suspension broken', label: 'Luftfjæring', category: 'Suspension / chassis', lvl: 4 },
  { id: 234, value: 'Broken spring', label: 'Fjæring', category: 'Suspension / chassis', lvl: 4 },
  { id: 235, value: 'Bearing broken', label: 'Hjuloppheng ødelagt', category: 'Suspension / chassis', lvl: 4 },

  // Tire
  { id: 236, value: 'Tire', label: 'Dekk', category: 'Tire', lvl: 3 },
  { id: 237, value: 'Wheel fell off', label: 'Hjul falt av', category: 'Tire', lvl: 4 },
  { id: 238, value: 'Puncture while driving', label: 'Punktering under kjøring', category: 'Tire', lvl: 4 },
  { id: 239, value: 'Puncture when stationary', label: 'Punktert mens parkert', category: 'Tire', lvl: 4 },

  // Transmission
  { id: 240, value: 'Transmission', label: 'Gir / Drivverk / Clutch', category: 'Transmission', lvl: 3 },
  { id: 241, value: 'Engine running, car not moving', label: 'Motor går, bil kjører ikke', category: 'Transmission', lvl: 4 },
  { id: 242, value: 'Abnormal noise from transmission', label: 'Ulyd fra girkasse', category: 'Transmission', lvl: 4 },

  // Warning lights
  { id: 243, value: 'Warning lights', label: 'Varsellampe', category: 'Warning lights', lvl: 3 },
  { id: 244, value: 'Warning lights Red', label: 'Rød varsellampe', category: 'Warning lights', lvl: 4 },
  { id: 245, value: 'Warning lights Yellow', label: 'Gul varsellampe', category: 'Warning lights', lvl: 4 },

  // Additional entries for other categories
  { id: 301, value: 'Breakdown (other)', label: 'Driftsstans, annet', category: 'Breakdown', lvl: 2 },
  { id: 302, value: 'No breakdown', label: 'Ingen driftsstans', category: 'Breakdown', lvl: 3 },
  { id: 303, value: 'Did not start', label: 'Startet ikke', category: 'Start', lvl: 3 },

  // Fire
  { id: 606, value: 'Fire', label: 'Brann', category: 'Fire', lvl: 3 },
  { id: 607, value: 'Fire while driving', label: 'Brann under kjøring', category: 'Fire', lvl: 4 },
  { id: 608, value: 'Fire while parked', label: 'Brann i parkert bil', category: 'Fire', lvl: 4 },

  // Transport
  { id: 801, value: 'Transport', label: 'Transport', category: 'Transport', lvl: 3 },
  { id: 802, value: 'Transport of non-drivable vehicle', label: 'Transport av ikke kjørbar bil', category: 'Transport', lvl: 4 },
  { id: 803, value: 'Transport of drivable vehicle', label: 'Transport av kjørbar bil', category: 'Transport', lvl: 4 },

  // Drivstofftom EL
  { id: 901, value: 'Out of charge (Electric Vehicle)', label: 'Drivstofftom EL', category: 'Out of charge', lvl: 3 },
  { id: 902, value: 'Wrong fueling', label: 'Feilfylling', category: 'Out of charge', lvl: 4 },
  { id: 903, value: 'Out of fuel', label: 'Drivstofftom', category: 'Out of charge', lvl: 4 },

  // Key
  { id: 1001, value: 'Key', label: 'Nøkkel', category: 'Key', lvl: 3 },
  { id: 1002, value: 'Key locked inside, engine running', label: 'Nøkkel innelåst, motor går', category: 'Key', lvl: 4 },
  { id: 1003, value: 'Key locked inside', label: 'Innelåst nøkkel', category: 'Key', lvl: 4 },
  { id: 1004, value: 'Lost key', label: 'Mistet nøkkel', category: 'Key', lvl: 4 },
  { id: 1005, value: 'Broken Key', label: 'Ødelagt nøkkel', category: 'Key', lvl: 4 },
];

export const categoryTranslations = {
  Transport: 'Transport',
  Collision: 'Kollisjon',
  'Does not start': 'Startvansker',
  Electrical: 'Elektrisk problem',
  'Body / accessories': 'Chassis/ påbygg',
  Engine: 'Motorfeil',
  Steering: 'Styring',
  Brakes: 'Bremser',
  'Suspension / chassis': 'Hjuloppheng',
  Tire: 'Dekk',
  Leak: 'Lekkasje',
  Transmission: 'Gir / Drivverk / Clutch',
  Fire: 'Brann',
  Key: 'Nøkkel',
  'Warning lights': 'Varsellampe',
  'Out of charge': 'Drivstofftom EL',
  'Other breakdown': 'Driftsstans, annet',
  'Other accident': 'Annet uhell',
};
