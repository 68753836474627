import { create } from 'zustand';

export const usePcdStore = create((set) => ({
  countryCode: 'NO',
  registrationNumber: '',
  pcdData: null,
  isLoading: false,
  isError: false,
  hasSearched: false,

  // Setting data
  setPcdData: (details) => set({ pcdData: details }),
  setLoading: (isLoading) => set({ loading: isLoading }),
  setError: (isError) => set({ error: isError }),
  setCountryCode: (countryCode) => set({ value: countryCode }),
  setRegistrationNumber: (number) => set({ registrationNumber: number }),
  clearPcdStore: () => set({ pcdData: null }),
}));
