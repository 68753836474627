import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Alert, AlertTitle, Button, Tooltip } from '@mui/material';
import { usePcdStore } from '../../hooks/store';

const ReviewFormButton = () => {
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const { clearPcdStore } = usePcdStore();

  const handleClick = () => {
    setButtonClicked(true);
    clearPcdStore();
    navigate('/case');
  };

  return (
    <>
      <Tooltip title="*IKKE KLIKK PÅ KNAPPEN FØR SAKEN ER LØST I SALESFORCE" placement="top" color="red">
        <Button
          component={Link}
          to="/case"
          variant="contained"
          color="success"
          fullWidth
          disabled={buttonClicked}
          onClick={() => {
            handleClick();
          }}
          sx={{
            height: '50px',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#006400',
            },
          }}
        >
          Ny sak
        </Button>
      </Tooltip>
      {buttonClicked && (
        <Alert severity="success" sx={{ marginTop: 2 }}>
          <AlertTitle>Sucess</AlertTitle>
          <strong>Saken har blitt oppdatert!</strong>
        </Alert>
      )}
    </>
  );
};

export default ReviewFormButton;
