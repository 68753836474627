import styled from "@emotion/styled";
import { MenuItem } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)`
 border: 1px solid #e0e0e0;
 border-radius: 8px;
 margin: 4px 0;
 padding: 8px;
 transition: box-shadow 0.3s ease, transform 0.3s ease;

 &:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
 }
`;
