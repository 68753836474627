import React, { useState } from 'react';
import { LocationSearch } from './LocationSearch';
import { LocationDescription } from './LocationDescription';
import { AzureMapFormik } from '../../../Map/AzureMapFormik';

export const LocationContainer = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);

  const handleMapToggle = () => {
    setIsMapOpen((prev) => !prev);
  };

  return (
    <>
      <LocationSearch isMapOpen={isMapOpen} onMapToggle={handleMapToggle} />
      {isMapOpen && <AzureMapFormik isMapOpen={true} />}
      <LocationDescription />
    </>
  );
};
