import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useFormikContext } from 'formik';
import { priorityNO } from '../../../../options/priority';
import { CaseDate } from './CaseDate';

export const CasePrioritySelector = () => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const value = values.incident?.priority;
  const error = touched.incident?.priority && errors.incident?.priority;

  const handleChange = (e) => {
    const newValue = e.target.value;
    setFieldValue('incident.priority', newValue);
  };

  React.useEffect(() => {
    if (value === undefined) {
      setFieldValue('incident.priority', '3');
    }
  }, [value, setFieldValue]);

  return (
    <Tooltip title="Select priority" placement="top">
      <FormControl fullWidth variant="outlined" error={Boolean(error)}>
        <InputLabel id="priority-select-label">Priority</InputLabel>
        <Select labelId="priority-select-label" id="priority-select" value={value || '3'} onChange={handleChange} onBlur={() => setFieldTouched('incident.priority', true)} label="Priority">
          {priorityNO.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <span>
                {option.label}{' '}
                <small>
                  <i>({option.tooltip})</i>
                </small>
              </span>
            </MenuItem>
          ))}
        </Select>

        {error && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Box>
        )}

        {value === '1' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              This is for police jobs only.
            </Typography>
          </Box>
        )}
        {value === '2' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              Contact the station by phone after the case has been dispatched.
            </Typography>
          </Box>
        )}

        {value === '5' && <CaseDate name="dateTime" />}
      </FormControl>
    </Tooltip>
  );
};
