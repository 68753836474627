import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

export const CaseInfoContainer = ({ name, phone, location }) => {
  return (
    <Card
      sx={{
        boxShadow: 2,
      }}
    >
      <Typography variant="h2">Saksinfo:</Typography>
      <CardContent>
        {!name ? <Typography color="red">Navn på kontaktperson: Mangler</Typography> : <Typography>Navn på kontaktperson: {name}</Typography>}
        {!phone ? <Typography color="red">Telefonnummer til kontaktperson: Mangler</Typography> : <Typography>Telefonnummer til kontaktperson: {phone}</Typography>}
        {!location ? <Typography color="red">Bilens lokasjon: Mangler</Typography> : <Typography>Bilens lokasjon: {location}</Typography>}
      </CardContent>
    </Card>
  );
};
