export const errorSwitch = (value404, error) => {
  let errorMessage;
  const statusCode = error.status;

  switch (statusCode) {
    case 400:
      errorMessage = 'Ugyldig forespørsel';
      break;
    case 401:
      errorMessage = 'Uautorisert';
      break;
    case 403:
      errorMessage = 'Forbudt';
      break;
    case 404:
      errorMessage = value404 + 'ikke funnet' || 'Forespurte data ble ikke funnet';
      break;
    case 405:
      errorMessage = 'Metode ikke tillatt';
      break;
    case 406:
      errorMessage = 'Ikke akseptabel';
      break;
    case 409:
      errorMessage = 'Konflikt';
      break;
    case 408:
      errorMessage = 'Tid for forespørsel er utløpt';
      break;
    case 500:
      errorMessage = 'Intern serverfeil';
      break;
    case 502:
      errorMessage = 'Dårlig gateway';
      break;
    case 504:
      errorMessage = 'Gateway timeout';
      break;
    default:
      errorMessage = error.message || 'En uventet feil oppstod';
  }

  return errorMessage;
};
