import React from 'react';
import { StyledMenuItem, StyledBox, StyledAddressBox, StyledTypography, StyledCountry } from './styledMenuItems';
import { getSearchIcon } from '../../hooks/helpers/getSearchIcon';
import { Box } from '@mui/material';

export const LocationMenuItem = ({ option, handleOptionSelect, ...props }) => {
  const { address, type, poi, chargingPark } = option;
  const { name, classifications } = poi || {};
  const { freeformAddress, streetName, streetNumber, postalCode, country } = address || {};
  const classificationCodes = classifications?.map((cls) => cls.code) || ['Unknown'];
  const iconSelection = classificationCodes[0] || type;

  const icon = getSearchIcon(iconSelection);

  const handleClick = () => {
    handleOptionSelect(option);
  };

  return (
    <StyledMenuItem {...props} onClick={handleClick}>
      <StyledBox>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          {icon}
          <StyledTypography>
            <b>{chargingPark && name ? 'Lade stasjon' : name || streetName}</b>
          </StyledTypography>
        </Box>
        {(freeformAddress || streetNumber || postalCode || country) && (
          <StyledAddressBox>
            {freeformAddress && <StyledTypography variant="body1">{freeformAddress}</StyledTypography>}
            {country && <StyledCountry variant="caption"> - {country}</StyledCountry>}
          </StyledAddressBox>
        )}
      </StyledBox>
    </StyledMenuItem>
  );
};
