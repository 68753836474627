import {
  ShoppingBag,
  Church,
  Hiking,
  AttachMoney,
  Restaurant,
  DirectionsBoat,
  Groups,
  DirectionsCar,
  Room,
  Attractions,
  EvStation,
  Terrain,
  FlightTakeoff,
  TheaterComedy,
  Business,
  DirectionsRailway,
  DirectionsBus,
  LocalCarWash,
  LocalGasStation,
  LocalPolice,
  Park,
  Anchor,
  Info,
  GolfCourse,
  Hotel,
  SportsScore,
  Museum,
  ShoppingCart,
  LocalHospital,
  LocalFireDepartment,
  School,
  LocalPharmacy,
  Movie,
  LocalBar,
  Bolt,
  CarRepair,
  LocalParking,
  LocalLibrary,
  FamilyRestroom,
  LocalGroceryStore,
  Place,
} from '@mui/icons-material';

const colors = {
  primary: '#1976d2',
  secondary: '#ff5722',
  success: '#4caf50',
  error: '#f44336',
  warning: '#ffc107',
  info: '#03a9f4',
  neutral: '#9e9e9e',
  pink: '#e91e63',
  purple: '#9c27b0',
  lightGreen: '#8bc34a',
  deepPurple: '#8e24aa',
};

export const getSearchIcon = (classification) => {
  switch (classification) {
    case 'COMPANY':
      return <Business sx={{ color: colors.primary }} />;
    case 'SHOP':
      return <ShoppingBag sx={{ color: colors.secondary }} />;
    case 'REPAIR_FACILITY':
      return <CarRepair sx={{ color: colors.success }} />;
    case 'PLACE_OF_WORSHIP':
      return <Church sx={{ color: colors.purple }} />;
    case 'CAMPING_GROUND':
      return <Hiking sx={{ color: colors.lightGreen }} />;
    case 'BANK':
      return <AttachMoney sx={{ color: colors.warning }} />;
    case 'RESTAURANT':
      return <Restaurant sx={{ color: colors.pink }} />;
    case 'FERRY_TERMINAL':
      return <DirectionsBoat sx={{ color: colors.info }} />;
    case 'NON_GOVERNMENTAL_ORGANIZATION':
      return <Groups sx={{ color: colors.neutral }} />;
    case 'AUTOMOTIVE_DEALER':
      return <DirectionsCar sx={{ color: colors.error }} />;
    case 'IMPORTANT_TOURIST_ATTRACTION':
      return <Attractions sx={{ color: colors.deepPurple }} />;
    case 'ELECTRIC_VEHICLE_STATION':
      return <EvStation sx={{ color: colors.success }} />;
    case 'AIRPORT':
      return <FlightTakeoff sx={{ color: colors.neutral }} />;
    case 'THEATER':
      return <TheaterComedy sx={{ color: colors.deepPurple }} />;
    case 'RAILWAY_STATION':
      return <DirectionsRailway sx={{ color: colors.neutral }} />;
    case 'BUS_STATION':
      return <DirectionsBus sx={{ color: colors.neutral }} />;
    case 'GAS_STATION':
      return <LocalGasStation sx={{ color: colors.error }} />;
    case 'CAR_WASH':
      return <LocalCarWash sx={{ color: colors.success }} />;
    case 'POLICE_STATION':
      return <LocalPolice sx={{ color: colors.error }} />;
    case 'PARK_RECREATION_AREA':
      return <Park sx={{ color: colors.success }} />;
    case 'MARINA':
      return <Anchor sx={{ color: colors.info }} />;
    case 'TOURIST_INFORMATION_OFFICE':
      return <Info sx={{ color: colors.neutral }} />;
    case 'GOLF_COURSE':
      return <GolfCourse sx={{ color: colors.success }} />;
    case 'HOTEL_MOTEL':
      return <Hotel sx={{ color: colors.primary }} />;
    case 'ADVENTURE_SPORTS_VENUE':
      return <SportsScore sx={{ color: colors.primary }} />;
    case 'CULTURAL_CENTER':
      return <Museum sx={{ color: colors.deepPurple }} />;
    case 'SHOPPING_CENTER':
      return <ShoppingCart sx={{ color: colors.secondary }} />;
    case 'HOSPITAL_POLYCLINIC':
      return <LocalHospital sx={{ color: colors.neutral }} />;
    case 'FIRE_STATION':
      return <LocalFireDepartment sx={{ color: colors.error }} />;
    case 'SCHOOL':
      return <School sx={{ color: colors.primary }} />;
    case 'PHARMACY':
      return <LocalPharmacy sx={{ color: colors.success }} />;
    case 'CINEMA':
      return <Movie sx={{ color: colors.pink }} />;
    case 'CAFE_PUB':
      return <LocalBar sx={{ color: colors.pink }} />;
    case 'PRIMARY_RESOURCE_UTILITY':
      return <Bolt sx={{ color: colors.warning }} />;
    case 'OPEN_PARKING_AREA':
      return <LocalParking sx={{ color: colors.neutral }} />;
    case 'LIBRARY':
      return <LocalLibrary sx={{ color: colors.neutral }} />;
    case 'REST_AREA':
      return <FamilyRestroom sx={{ color: colors.neutral }} />;
    case 'MARKET':
      return <LocalGroceryStore sx={{ color: colors.success }} />;
    case 'OTHER':
      return <Place sx={{ color: colors.neutral }} />;
    case 'Street':
      return <DirectionsCar sx={{ color: colors.error }} />;
    case 'Geography':
      return <Terrain sx={{ color: colors.success }} />;
    default:
      return <Room sx={{ color: colors.neutral }} />;
  }
};
