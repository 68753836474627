import { create } from 'zustand';

const useSearchStore = create((set) => ({
  searchResult: null, // Initially set to null or empty
  searchPerformed: false,

  setSearchResult: (result) =>
    set({
      searchResult: result,
      searchPerformed: true,
    }),

  clearSearchResult: () =>
    set({
      searchResult: null,
      searchPerformed: false,
    }),
}));

export default useSearchStore;
