import React, { useEffect, useState } from 'react';
import { Box, Alert } from '@mui/material';
import CaseList from './CaseList';
import CarContainerCard from './CarContainerCard';
import LoadingCar from '../../../Loading/LoadingCar';
import { usePcdStore, useRsaDataStore } from '../../../../hooks/store';

const SearchResultsDisplay = () => {
  const { pcdData, isError, isLoading } = usePcdStore();
  const { rsaCaseDetails } = useRsaDataStore();
  const [weightAlert, setWeightAlert] = useState(false);

  useEffect(() => {
    if (pcdData) {
      const weight = pcdData?.vehicleData?.technicalData?.weight;
      if (weight && weight > 3500) {
        setWeightAlert(true);
      } else {
        setWeightAlert(false);
      }
    }
  }, [pcdData]);

  if (!pcdData) {
    return null;
  }

  if (isError) {
    return (
      <Alert severity="error" variant="filled">
        {isError.message}
      </Alert>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {isLoading ? (
        <LoadingCar />
      ) : (
        <>
          {weightAlert && (
            <Alert severity="error" sx={{ backgroundColor: '#ff5757', boxShadow: 2, color: 'black' }}>
              <b>Vekten på bilen er over 3500. Dette er en tungbil, saken må opprettes av team truck!</b>
            </Alert>
          )}
          <CarContainerCard data={pcdData} />
          <CaseList caseData={rsaCaseDetails} />
        </>
      )}
    </Box>
  );
};

export default SearchResultsDisplay;
