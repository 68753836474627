export const mapSearchResult = (result, lat = null, lon = null) => {
  const address = result.address || {};
  const classifications = result.poi?.classifications || [];
  const categories = result.poi?.categories || [];
  const isGasStation = classifications.some((cls) => cls.code === 'PETROL_STATION');

  return {
    key: result.id || null,
    type: result.type || null,
    name: result.poi?.name,
    label: address.freeformAddress,
    position: {
      lat: lat || result.position?.lat || null,
      lon: lon || result.position?.lon || null,
    },
    address: {
      address: address.freeformAddress || '',
      freeformAddress: address.freeformAddress || '',
      streetNumber: address.streetNumber || '',
      street: address.streetName || '',
      countryCode: address.countryCode || '',
      countryCodeISO: address.countryCodeISO3 || '',
      city: address.localName || address.municipality || '',
      postalCode: address.postalCode || '',
      country: address.country || '',
    },
    poi: {
      name: result.poi?.name || '',
      classification: classifications.flatMap((cls) => ({
        code: cls.code,
        names: cls.names.map((name) => ({
          nameLocale: name.nameLocale || null,
          name: name.name || null,
        })),
      })),
      categories: categories.map((category) => ({
        name: category,
      })),
      fuelTypes: isGasStation ? result.fuelTypes || [] : [],
      vehicleTypes: isGasStation ? result.vehicleTypes || [] : [],
      gasStation: isGasStation,
    },
  };
};
