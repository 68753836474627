import React from 'react';
import { FormControl, InputAdornment, IconButton, TextField, MenuItem, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { phoneNumberFormat } from '../../../../utils/format/phoneNumerFormat';
import { usePhoneNumberHandlers } from '../../../../hooks/handler/usePhoneNumberHandlers';

export const ContactNumber = () => {
  const { setFieldValue, values, errors } = useFormikContext();
  const { selectedCountry, inputError, handleInputChange, handleCountryChange } = usePhoneNumberHandlers(values, setFieldValue);

  const error = inputError || errors.onSiteContact?.phone;

  return (
    <Tooltip title="Skriv inn kontakttelefonnummer" placement="top">
      <FormControl variant="outlined" fullWidth error={!!error}>
        <TextField
          variant="outlined"
          name="onSiteContact.phone"
          placeholder="Kontakt telefonnummer"
          value={values.onSiteContact.phone || ''}
          onChange={handleInputChange}
          error={!!error}
          helperText={error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField select value={selectedCountry} onChange={handleCountryChange} variant="standard" style={{ marginRight: 8 }}>
                  {phoneNumberFormat.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      ({option.name}) {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            endAdornment: error ? (
              <InputAdornment position="end">
                <IconButton>
                  <ErrorIcon color="error" />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      </FormControl>
    </Tooltip>
  );
};
