import { regCode } from '../options/regCode';
import { registrationFormat } from './format/registrationFormat';

export const validateRegistrationNumber = (number, country) => {
  console.log('Validation - CountryCode:', country);
  console.log('Validation - Registration Number:', number);

  const pattern = registrationFormat[country];

  console.log('Validation - pattern:', pattern);

  return pattern && !pattern.test(number) ? `Ugyldig format for ${country}` : '';
};

export const validateLicensePlate = (countryCode, licensePlate) => {
  const country = regCode.find((item) => item.code === countryCode);

  if (!country) {
    console.error(`Invalid country code: ${countryCode}`);
    return 'Ugyldig landskode';
  }

  const regEx = country.regEx;

  if (regEx && !regEx.test(licensePlate)) {
    return `Ugyldig format for  ${country.name}`;
  }

  return '';
};
