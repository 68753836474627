import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { regCode } from '../../options/regCode';
import { useAxiosInstance } from '../../api/axiosInstanceTwo';
import { usePcdStore, useRsaDataStore } from '../store';
import { mapOwnerData, mapVehicleData } from '../../utils/Mapping/mapData';
import { errorSwitch } from '../../utils/switch/errorSwtich';

export const usePcdSearch = () => {
  const axiosInstance = useAxiosInstance();
  const { setPcdData, setLoading, setError, isLoading, isError } = usePcdStore();
  const { setRsaCaseDetails } = useRsaDataStore();
  const { setFieldValue, setFieldError } = useFormikContext();

  const pcdUrl = `${process.env.REACT_APP_PCD_API_BASE_URL}/pcd/search`;

  const fetchRegData = useCallback(
    async (registrationNumber, countryCode) => {
      try {
        setLoading('loading');
        setError(null);

        const country = regCode.find((item) => item.code === countryCode);
        const countryName = country ? country.name : 'Ukjent';

        const response = await axiosInstance.post(pcdUrl, {
          registrationNumber: registrationNumber,
          countryCode: countryName,
        });

        if (response.status === 200) {
          const responseData = response.data;
          const owner = responseData.owner?.[0];
          const vehicle = responseData.vehicleData;

          setFieldValue('pcdData', {
            owner: mapOwnerData(owner),
            vehicle: mapVehicleData(vehicle),
          });

          setPcdData(responseData);
        } else {
          const errorMessage = errorSwitch('Registreringsnummer ', { status: response.status, message: 'Fant ikke kjøretøydata' });
          setError({ status: response.status, message: errorMessage });
          setFieldError('searchValues.registrationNumber', errorMessage);
        }

        const rsaResponse = await axiosInstance.get(`${process.env.REACT_APP_RSA_API_BASE_URL}?registrationNumber=${registrationNumber}`);
        setRsaCaseDetails(rsaResponse.data);
      } catch (err) {
        console.error('Feil ved henting av registreringsdata:', err);

        const errorMessage = errorSwitch('Registreringsnummer ', err.response || { message: 'En ukjent feil oppstod' });
        setError({ status: err.response?.status, message: errorMessage });
        setFieldError('searchValues.registrationNumber', errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, axiosInstance, pcdUrl, setRsaCaseDetails, setPcdData, setFieldValue, setFieldError]
  );

  return { isLoading, isError, fetchRegData };
};
