import * as Yup from 'yup';

export const formValidation = Yup.object({
  searchValues: Yup.object({
    registrationNumber: Yup.string().nullable(),
    registrationNumberCountry: Yup.string().required('Registrerings land er påkrevd'),
    countryCode: Yup.string().required('Landskode er påkrevd'),
  }),
  pcdData: Yup.object({
    vehicle: Yup.object({
      registrationNumberCountry: Yup.string().nullable(),
      registrationNumber: Yup.string().nullable(),
      insuranceCompany: Yup.string().nullable(),
      dateOfFirstRegistration: Yup.date().nullable().nullable(),
      firstRegistrationCountry: Yup.string().nullable(),
      year: Yup.number().nullable(),
      vin: Yup.string().nullable(),
      make: Yup.string().nullable(),
      model: Yup.string().nullable(),
      bodyType: Yup.string().nullable(),
      colour: Yup.string().nullable(),
      technicalData: Yup.object({
        maxTrailerWeight: Yup.string().nullable(),
        automaticTransmission: Yup.string().nullable(),
        fourWheelDrive: Yup.string().nullable(),
        wheelDimensions: Yup.array().nullable(),
        wheelbase: Yup.string().nullable(),
        fuelType: Yup.string().nullable(),
        numberOfAxlesInOperation: Yup.string().nullable(),
        totalNumberOfAxles: Yup.number().nullable(),
        weight: Yup.string().nullable(),
        totalWeight: Yup.string().nullable(),
        width: Yup.number().nullable(),
        length: Yup.number().nullable(),
      }),
    }),
    owner: Yup.object({
      firstName: Yup.string().nullable(),
      lastName: Yup.string().nullable(),
      idNumber: Yup.string().nullable(),
      organizationNumber: Yup.string().nullable(),
      isCompany: Yup.boolean().nullable(),
      address: Yup.object({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        zip: Yup.string().nullable(),
      }),
    }),
  }),
  onSiteContact: Yup.object({
    contactName: Yup.string().required('Kontaktpersonens navn er påkrevd'),
    phone: Yup.string().required('Telefonnummer er påkrevd'),
    channel: Yup.string().required('Kanal er påkrevd'),
  }),
  incident: Yup.object().shape({
    incidentCause: Yup.string().required('Dekningsårsak er påkrevd'),
    incidentDescription: Yup.string().nullable(),
    priority: Yup.string().required('Prioritet er påkrevd'),
  }),
  location: Yup.object().shape({
    address: Yup.string().required('Adresse er påkrevd'),
    postalCode: Yup.string().nullable(),
    city: Yup.string().required('By er påkrevd'),
    country: Yup.string().required('Land er påkrevd'),
    countryCodeISO: Yup.string().required('Landskode er påkrevd'),
    lon: Yup.string().required('Lengdegrad er påkrevd'),
    lat: Yup.string().required('Breddegrad er påkrevd'),
  }),
});
