import React from 'react';
import { useField } from 'formik';
import { MenuItem, Select } from '@mui/material';
import { useRegistrationCountryChange } from '../../../../hooks/handler/useRegistrationCountryChange';
import { regCode } from '../../../../options/regCode';

export const RegistrationCountry = () => {
  const [field] = useField('searchValues.countryCode');
  const { handleInputChange } = useRegistrationCountryChange();

  const { value } = field;

  return (
    <Select {...field} value={value} onChange={handleInputChange} name="searchValues.countryCode" variant="standard">
      {regCode.map(({ code, name }) => (
        <MenuItem key={code} value={code}>
          {code}{' '}
          <i>
            <small>({name})</small>
          </i>
        </MenuItem>
      ))}
    </Select>
  );
};
