import { useState } from 'react';
import { useFormikContext } from 'formik';
import { usePcdStore } from '../store/usePcdStore';
import { regCode } from '../../options/regCode';
import { validateLicensePlate } from '../../utils/validateRegistrationNumber';

export const useRegistrationCountryChange = () => {
  const { setFieldValue, values, setFieldError } = useFormikContext();
  const { setCountryCode } = usePcdStore();
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleInputChange = (e) => {
    const selectedValue = e.target.value;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      const selectedCountry = regCode.find((c) => c.code === selectedValue);

      if (selectedCountry) {
        const error = validateLicensePlate(selectedValue, values.searchValues.registrationNumber);
        if (error) {
          setFieldError('searchValues.registrationNumber', error);
        } else {
          setFieldError('searchValues.registrationNumber', '');
        }
        setCountryCode(selectedValue);
        setFieldValue('searchValues.countryCode', selectedValue);
        setFieldValue('searchValues.registrationNumberCountry', selectedCountry.name);
      } else {
        setFieldError('searchValues.countryCode', 'Invalid country code');
        console.error('Invalid country code selected');
      }
    }, 500);

    setTypingTimeout(newTimeout);
  };

  return { handleInputChange };
};
