export const phoneNumberFormat = [
  { code: '+43', name: 'Austria', regex: /^\+43\d{1,15}$/ },
  { code: '+32', name: 'Belgium', regex: /^\+32\d{8}$/ },
  { code: '+359', name: 'Bulgaria', regex: /^\+359\d{8}$/ },
  { code: '+385', name: 'Croatia', regex: /^\+385\d{8}$/ },
  { code: '+357', name: 'Cyprus', regex: /^\+357\d{8}$/ },
  { code: '+420', name: 'Czech Republic', regex: /^\+420\d{9}$/ },
  { code: '+45', name: 'Denmark', regex: /^\+45\d{8}$/ },
  { code: '+372', name: 'Estonia', regex: /^\+372\d{7}$/ },
  { code: '+358', name: 'Finland', regex: /^\+358\d{8}$/ },
  { code: '+33', name: 'France', regex: /^\+33\d{9}$/ },
  { code: '+49', name: 'Germany', regex: /^\+49\d{10,11}$/ },
  { code: '+30', name: 'Greece', regex: /^\+30\d{10}$/ },
  { code: '+36', name: 'Hungary', regex: /^\+36\d{9}$/ },
  { code: '+354', name: 'Iceland', regex: /^\+354\d{7}$/ },
  { code: '+353', name: 'Ireland', regex: /^\+353\d{8}$/ },
  { code: '+39', name: 'Italy', regex: /^\+39\d{9,10}$/ },
  { code: '+371', name: 'Latvia', regex: /^\+371\d{8}$/ },
  { code: '+370', name: 'Lithuania', regex: /^\+370\d{8}$/ },
  { code: '+352', name: 'Luxembourg', regex: /^\+352\d{6,8}$/ },
  { code: '+356', name: 'Malta', regex: /^\+356\d{8}$/ },
  { code: '+373', name: 'Moldova', regex: /^\+373\d{8}$/ },
  { code: '+377', name: 'Monaco', regex: /^\+377\d{8}$/ },
  { code: '+31', name: 'Netherlands', regex: /^\+31\d{9}$/ },
  { code: '+47', name: 'Norge', regex: /^\+47\d{8}$/ },
  { code: '+48', name: 'Poland', regex: /^\+48\d{9}$/ },
  { code: '+351', name: 'Portugal', regex: /^\+351\d{9}$/ },
  { code: '+40', name: 'Romania', regex: /^\+40\d{9}$/ },
  { code: '+7', name: 'Russia', regex: /^\+7\d{10}$/ },
  { code: '+378', name: 'San Marino', regex: /^\+378\d{7}$/ },
  { code: '+381', name: 'Serbia', regex: /^\+381\d{8}$/ },
  { code: '+421', name: 'Slovakia', regex: /^\+421\d{9}$/ },
  { code: '+386', name: 'Slovenia', regex: /^\+386\d{8}$/ },
  { code: '+34', name: 'Spain', regex: /^\+34\d{9}$/ },
  { code: '+46', name: 'Sweden', regex: /^\+46\d{8}$/ },
  { code: '+41', name: 'Switzerland', regex: /^\+41\d{9}$/ },
  { code: '+380', name: 'Ukraine', regex: /^\+380\d{9}$/ },
  { code: '+44', name: 'United Kingdom', regex: /^\+44\d{10}$/ },
  { code: '+972', name: 'Israel', regex: /^\+972\d{9}$/ },
];
