import React, { useRef, useState, useEffect } from 'react';
import atlas, { HtmlMarker } from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import '../../Styles/map/AzureMaps.css';
import SearchResultCard from './Props/SearchResultCard';
import { searchCoordinates } from '../../api/azureMapsService';
import useSearchStore from '../../hooks/store/useSearchStore';
import { useFormikContext } from 'formik';

export const AzureMapFormik = () => {
  const { values, setFieldValue } = useFormikContext();
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [marker, setMarker] = useState(null);
  const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY;

  const { searchResult, setSearchResult } = useSearchStore((state) => ({
    searchResult: state.searchResult,
    setSearchResult: state.setSearchResult,
  }));

  useEffect(() => {
    const markerPosition = [values.location.lon, values.location.lat];
    const defaultPosition = [10.75, 59.91];
    const defaultZoom = 12;
    const mapPosition = markerPosition.some((coord) => coord) ? markerPosition : defaultPosition;

    if (mapRef.current && !mapInstance) {
      const map = new atlas.Map(mapRef.current, {
        center: mapPosition,
        zoom: defaultZoom,
        showLogo: false,
        showFeedbackLink: false,
        renderWorldCopies: false,
        pitch: 0,
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: subscriptionKey,
        },
      });

      map.events.add('ready', () => {
        setMapInstance(map);
        map.controls.add(new atlas.control.TrafficControl(), { position: 'top-right' });
        map.events.add('contextmenu', handleMapRightClick);
      });
    }
  }, [subscriptionKey, mapInstance]); //eslint-disable-line

  useEffect(() => {
    if (mapInstance && searchResult?.position?.lat && searchResult?.position?.lon) {
      initializeMarker(mapInstance, searchResult);
    }
  }, [searchResult, mapInstance]); //eslint-disable-line

  const initializeMarker = (map, result) => {
    if (marker) {
      map.markers.remove(marker);
    }

    const { lat, lon } = result.position || {};
    if (!lat || !lon) {
      console.error('Invalid position values:', lat, lon);
      return;
    }

    const newMarker = createMarker(map, [lon, lat]);
    map.markers.add(newMarker);
    map.setCamera({
      center: [lon, lat],
      zoom: 14,
    });

    setMarker(newMarker);
  };

  const handleMapRightClick = async (e) => {
    if (!mapInstance) return;

    const newPosition = [e.position[0], e.position[1]];
    if (marker) {
      marker.setOptions({ position: newPosition });
    } else {
      const newMarker = createMarker(mapInstance, newPosition);
      mapInstance.markers.add(newMarker);
      setMarker(newMarker);
    }

    mapInstance.setCamera({ center: newPosition, zoom: 14 });

    const addressDetails = await searchCoordinates(newPosition[1], newPosition[0]);
    if (addressDetails) {
      updateFormikAndStore(newPosition, addressDetails);
    }
  };

  const createMarker = (map, position) => {
    const newMarker = new HtmlMarker({
      position: position,
      htmlContent: '<div class="pin__container"><div class="pin bounce"></div><div class="pulse"></div></div>',
      draggable: true,
      anchor: 'top',
    });

    if (map) {
      map.events.add('dragend', newMarker, async (e) => {
        const { position: newPosition } = e.target.getOptions();
        const [lon, lat] = newPosition;

        const addressDetails = await searchCoordinates(lat, lon);
        if (addressDetails) {
          updateFormikAndStore(newPosition, addressDetails);
        }
      });
    }

    return newMarker;
  };

  const updateFormikAndStore = (position, addressDetails) => {
    const [lon, lat] = position;

    setFieldValue('location.address', addressDetails.address.freeformAddress || '');
    setFieldValue('location.postalCode', addressDetails.address.postalCode || '');
    setFieldValue('location.city', addressDetails.address.city || '');
    setFieldValue('location.country', addressDetails.address.country || '');
    setFieldValue('location.countryCodeISO', addressDetails.address.countryCodeISO || '');
    setFieldValue('location.lon', lon || '');
    setFieldValue('location.lat', lat || '');

    setSearchResult({
      address: addressDetails.address.freeformAddress,
      postalCode: addressDetails.address.postalCode,
      city: addressDetails.address.city,
      country: addressDetails.address.country,
      countryCodeISO: addressDetails.address.countryCodeISO,
      position: { lat, lon },
      title: addressDetails.address.freeformAddress,
    });
  };

  const closeCard = () => setSearchResult(null);

  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
      {searchResult && <SearchResultCard addressSearch={searchResult} onClose={closeCard} />}
    </div>
  );
};
