import { Autocomplete, Box, Divider, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { categoryTranslations, coverCauses } from '../../../../options/CoverCasue';
import { useFormikContext } from 'formik';
import React from 'react';

export const CoverCauseSelector = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const coverCauseOptions = coverCauses.filter((option) => option.lvl === 4);
  return (
    <Tooltip title="Velg hva som har skjedd med kjøretøyet" placement="top">
      <FormControl fullWidth variant="outlined" error={Boolean(errors.incident?.incidentCause && errors.incident?.incidentCause)}>
        <Autocomplete
          options={coverCauseOptions}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.label}
          value={coverCauseOptions.find((option) => option.value === values.incident?.incidentCause) || null}
          onChange={(event, newValue) => {
            setFieldValue('incident.incidentCause', newValue ? newValue.value : '');
          }}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Velg en assistanseårsak"
              variant="outlined"
              fullWidth
              error={Boolean(errors.incident?.incidentCause && errors.incident?.incidentCause)}
              helperText={errors.incident?.incidentCause && errors.incident?.incidentCause}
            />
          )}
          renderGroup={(params) => (
            <Box key={params.group}>
              <Typography variant="h3" fontWeight={'bold'} marginTop={2} marginLeft={2}>
                {categoryTranslations[params.group] || params.group}
              </Typography>
              <Divider />
              <Typography key={(option) => option.id} variant="body1">
                {params.children}
              </Typography>
            </Box>
          )}
          key={(option) => option.id}
        />
      </FormControl>
    </Tooltip>
  );
};
