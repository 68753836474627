import { phoneNumberFormat } from '../../../utils/format/phoneNumerFormat';

export const validatePhoneNumber = (number, code) => {
  if (!number) {
    return '';
  }
  const formattedNumber = number.startsWith(code) ? number : `${code}${number.replace(/^\+/, '')}`;
  const country = phoneNumberFormat.find((item) => item.code === code);
  if (country?.regex && !country.regex.test(formattedNumber)) {
    return `Ugylding telefon nummer for ${country.name}`;
  }
  return '';
};
