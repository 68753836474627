import { countryToCurrency } from '../Mapping/countryToCurrency';
import { replaceEmptyStringsWithNull } from '../replaceEmptyStringsWithNull';

export const postRequest = (values) => {
  console.log('Skjema verdier ved post forespørsel: ', values);

  replaceEmptyStringsWithNull(values);

  const currentDateOnly = new Date().toISOString().split('T')[0];

  if (values.location?.lat && values.location?.lon) {
    values.location.lat = values.location.lat.toString();
    values.location.lon = values.location.lon.toString();
  }

  let countryCode = values?.location?.countryCodeISO;
  if (countryToCurrency[countryCode]) {
    values.currencyISOCode = countryToCurrency[countryCode];
  }
  const createCaseBody = {
    registrationNumber: values?.searchValues?.registrationNumber,
    countryCode: values?.searchValues?.countryCode,
    caseType: process.env.REACT_APP_CREATE_CASE_TYPE,
    currencyISOCode: values?.currencyISOCode,
    caseDescription: values?.caseDescription,
    incidentCause: values?.incident.incidentCause || null,
    incidentDescription: values?.incident?.incidentDescription || null,
    channel: values?.onSiteContact?.channel || null,
    priority: parseInt(values?.incident?.priority),
    incidentDate: currentDateOnly,
    desiredAssistanceFrom: values?.dateTime,
    accountId: values?.accountId || null,
    vehicle: {
      vin: values?.pcdData?.vehicle?.vin || null,
      model: values?.pcdData?.vehicle?.model || null,
      make: values?.pcdData?.vehicle?.make || null,
      type: values?.pcdData?.vehicle?.bodyType || null,
      insurance: values?.pcdData?.vehicle?.insuranceCompany || null,
      carProducedYear: values?.pcdData?.vehicle?.year || null,
    },
    onSiteContact: {
      contactId: values?.onSiteContact?.contactId || null,
      name: values?.onSiteContact?.contactName,
      phoneNumber: values?.onSiteContact?.countryCode + values?.onSiteContact?.phone,
    },
    caseLocation: {
      address: values?.location?.address || null,
      zip: values?.location?.postalCode || null,
      city: values?.location?.city || null,
      country: values?.location?.country || null,
      latitude: values?.location?.lat || null,
      longitude: values?.location?.lon || null,
      description: values?.location?.locationMessage || null,
    },
    agent: values?.agent || null,
  };

  return createCaseBody;
};
