import React, { useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { createCaseInitialValuies } from '../initialvalues/createCaseInitialValuies';
import { formValidation } from '../validation/formValidation';
import { useHandleForm } from './useHandleForm';
import { CasePrioritySelector, ContactChannel, ContactContainer, LocationContainer } from '../../../components/form';
import { FormWrapper } from '../../../components/form/FormWrapper';
import { CoverCauseContainer } from '../../../components/form/formInput/coverCauseInformation/CoverCauseContainer';
import { RegistrationContainer } from '../../../components/form/formInput/registrationNumber/RegistrationContainer';

export const CaseForm = () => {
  const { onSubmit } = useHandleForm();

  return (
    <>
      <Formik
        initialValues={createCaseInitialValuies}
        enableReinitialize={false}
        validationSchema={formValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, { setSubmitting, resetForm });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, isSubmitting }) => (
          <>
            <FormikValuesLogger values={values} errors={errors} />
            <FormWrapper>
              <ContactChannel />
              <ContactContainer />
              <RegistrationContainer />
              <CoverCauseContainer />
              <LocationContainer />
              <CasePrioritySelector />
              <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color="error" /> : 'Opprett sak'}
              </Button>
            </FormWrapper>
          </>
        )}
      </Formik>
    </>
  );
};

const FormikValuesLogger = ({ values, errors }) => {
  useEffect(() => {
    console.log('Skjema verdier oppdatert:', values);
    console.log('Skjema validering feilet:', errors);
  }, [values, errors]);

  return null;
};
